import { cloneDeep, find } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import {
	OtherResponsesList,
	OtherResponsesToggleButton,
} from './OtherResponses';
import { getQuestionTitle } from '../../utils';
import { generateTableData } from './utils';

function ComparisonRow({
	question,
	row,
	data,
	language,
	translations,
	questionList,
}) {
	const renderOtherRow = find(
		row.choices,
		i => i.choice.choice_type === 'other',
	);
	const [showOtherResponses, setShowOtherResponses] = useState(false);

	return (
		<>
			<tr>
				<td className="whitespace-pre-lines py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 break-words">
					{getQuestionTitle(
						row.answer,
						questionList,
						translations,
						language,
						row.nanoid,
						true,
					)}
				</td>
				{row.choices.map((item, index) => (
					<td
						key={index}
						className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono"
					>
						<span>{item.responseCount}</span>
					</td>
				))}
				{row.choices[row.choices.length - 1].choice.choice_type === 'other' &&
					row.choices[row.choices.length - 1].choice.nanoid !==
						'MOGeoV81PGpwtOnN' && (
						<td
							className={classNames(
								showOtherResponses ? 'border-r-4 border-r-indigo-600' : '',
								'text-right w-auto',
							)}
						>
							<OtherResponsesToggleButton
								className="mr-2"
								showOtherResponses={showOtherResponses}
								setShowOtherResponses={setShowOtherResponses}
							/>
						</td>
					)}
			</tr>
			{renderOtherRow && showOtherResponses && (
				<OtherResponsesList
					key={question.nanoid}
					data={data}
					filterKey={row.comparisonKey}
					filterValue={row.comparisonItemKey}
					filterRank={row.comparisonRankKey}
					question={question}
					language={language}
					colSpan={row.choices.length + 2}
				/>
			)}
		</>
	);
}

export default function Table({
	translations,
	language,
	data,
	labels,
	question,
	comparison,
	questionList,
	isCompareMode,
}) {
	const [showOtherResponses, setShowOtherResponses] = useState(false);

	const { t } = useTranslation();
	const q = cloneDeep(question);
	Array.from({ length: question.config.number_of_shape }, (_, i) => {
		q.choices.push({
			value: i + 1,
		});
	});

	const tableData = generateTableData(
		data,
		labels,
		q,
		comparison,
		'answer',
		'percentage',
	);

	return (
		<div className="overflow-hidden relative shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
			<div className="overflow-y-auto">
				<table className="w-full divide-y divide-gray-300 overflow-y-auto table-auto">
					<thead className="bg-gray-50">
						{!isCompareMode && (
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left"
								>
									{t('Answer')}
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right"
								>
									{t('Responses')}
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right"
								>
									{t('Percentage')}
								</th>
							</tr>
						)}
						{isCompareMode && (
							<tr>
								<th
									scope="col"
									className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 w-48 text-left "
								>
									{t('Comparison Item')}
								</th>
								{tableData[0].choices.map((item, index) => (
									<th
										key={index}
										scope="col"
										className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right w-20 break-words"
									>
										{item.choiceTitle}
									</th>
								))}
								{question.config.show_other && <th>&nbsp;</th>}
							</tr>
						)}
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{!isCompareMode && (
							<>
								{tableData.map((row, index) => (
									<tr key={index}>
										<td
											className={classNames(
												showOtherResponses && row.choice.choice_type === 'other'
													? 'border-l-4 border-l-indigo-600 sm:pl-5'
													: '',
												row.choice.choice_type === 'other'
													? 'items-center'
													: '',
												'whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 ',
											)}
										>
											<div className="flex items-center gap-2">
												{(question.question_type === 'single_selection' ||
													question.question_type === 'multiple_selection' ||
													question.question_type === 'yes_no') && (
													<div className="whitespace-pre-line break-all">
														{getQuestionTitle(
															row.answer,
															questionList,
															translations,
															language,
															row.choice.nanoid,
															true,
														)}
													</div>
												)}
												{question.question_type !== 'single_selection' &&
													question.question_type !== 'multiple_selection' &&
													question.question_type !== 'yes_no' && (
														<>{row.answer}</>
													)}

												{row.choice.choice_type === 'other' &&
													row.choice.nanoid !== 'MOGeoV81PGpwtOnN' && (
														<OtherResponsesToggleButton
															showOtherResponses={showOtherResponses}
															setShowOtherResponses={setShowOtherResponses}
														/>
													)}
											</div>
											{row.media && (
												<a
													href={row.media.url}
													target="_blank"
													rel="noreferrer"
												>
													<img src={row.media.url} className="w-32 rounded" />
												</a>
											)}
										</td>
										<td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono">
											<span>{row.responseCount}</span>
										</td>
										<td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono">
											<span>{row.percentage}</span>
										</td>
									</tr>
								))}
								{question.config.show_other && showOtherResponses && (
									<OtherResponsesList
										key={question.nanoid}
										data={data}
										question={q}
										language={language}
									/>
								)}
							</>
						)}

						{isCompareMode && (
							<>
								{tableData.map((row, index) => (
									<ComparisonRow
										key={index}
										question={question}
										questionList={questionList}
										translations={translations}
										row={row}
										data={data}
										language={language}
									/>
								))}
							</>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
}
